@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}
::-webkit-scrollbar {
  width: 6px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: #3ba4ec;
}
::-webkit-scrollbar-thumb {
  height: 20%;
  background-color: white;
  border-radius: 9em;
  // box-shadow: inset 1px 1px 10px #f3faf7;
}
header, img, button{
  user-select: none;
}

body {
  background-color: #f2f1ef;
}

.swiper {
  width: 100%;
  height: 100%;
  background-color: #f2f1ef;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: fit-content !important;
  background-color: #f2f1ef !important;
}

@media (max-width: 360px) {
  .cont-n {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;
  }
}

.cont-n {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}
.cont-n2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
}

.trun {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.infoCont {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 7 !important;
  -webkit-box-orient: vertical;
  line-height: 18.8px;
}

.trn {
  transform: translate(-50%, -50%);
}

.text {
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1000 - 320)));
}

.stic {
  position: sticky;
  top: 30px;
  left: 20px;
  z-index: 122;
}